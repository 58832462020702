<template>
  <div class="mc-container">
    <div class="cm-header">
      <img
        style="height: 60px; width: 50px"
        src="@/assets/logo.png"
        alt="logo"
      />
      <div class="cm-text-logo">Mobilecrosspay</div>
    </div>
    <div class="cm-content">
      <div class="cm-box">
        <p class="info">
          Mobilecrosspay, facilite le paiement mobile en Afrique. Payer et
          encaisser simplement à distance en toute sécurité.
          <img class="illustration" src="@/assets/sp.svg" alt="illustration" />
        </p>
      </div>
      <div class="cm-form">
        <div style="margin: auto; width: 220px">
          <ul class="steps is-horizontal">
            <li class="steps-segment">
              <span class="steps-marker"></span>
            </li>
            <li class="steps-segment is-active">
              <span class="steps-marker"></span>
            </li>
            <li class="steps-segment">
              <span class="steps-marker"></span>
            </li>
          </ul>
        </div>
        <p class="cm-form-title">
          Réinitialiser votre mot de passe <br />
          pour accéder à nos services
        </p>
        <form action="" autocomplete="off">
          <span class="d-inline-block cm-mb-8" for="email"
            >Adresse mail associée au compte</span
          >
          <text-input type="email" mainClass="control has-icons-left">
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </text-input>
          <p class="cm-mt-10">
            <button class="button is-fullwidth is-primary">Valider</button>
          </p>
          <div class="cm-info-bottom">
            <div>
              <router-link style="font-size: 15px" to="/signin"
                >Se connecter</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "../../components/TextInput.vue";
export default {
  components: { TextInput },
};
</script>

<style lang="scss" scoped>
.mc-container {
  background-color: #ecf7ff;
  width: 100%;
  height: 100vh;
  .cm-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 70px;
    width: 100%;
    img {
      height: 50px;
    }
    .cm-text-logo {
      margin-left: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.cm-content {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 20px;
  margin-left: 10%;
  margin-right: 10%;
}
.info {
  font-weight: bold;
  font-size: 18px;
}
.cm-form {
  background-color: #fff;
  min-height: 250px;
  max-height: 400px;
  border-radius: 5px;
  padding: 50px;
  .cm-form-title {
    text-align: center;
    color: #222f5a;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.cm-info-bottom {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .illustration {
    display: none !important;
  }
  .info {
    font-weight: bold;
    font-size: 18px;
    text-align: center !important;
  }
  .cm-content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    align-items: center;
  }
}
</style>